<style lang="scss">
.loading{
    position: fixed;
    left:0;
    top:40px;
    z-index: 3;
    background: #f8f8f8;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .loading-text{
        font-size: 18px;color: rgba(0, 0, 0, 0.8);
    }
    .loading-icon{
        font-size: 40px;
        color: rgba(0, 0, 0, 0.8);
    }
}
</style>

<template>
<div class="loading">

    <div class="loading-icon">
        <div class="el-icon-loading"></div>
    </div>

    <div class="loading-text">
        Loading
    </div>
</div>
</template>


<script>
export default{
    name:"loading"
}
</script>