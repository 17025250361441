// const console = {
//   log: () => { }
// }

import { Utils } from "run-scene-v2";
import bus from "./../../lib/bus";
// 声明变量
let camera, scene, controls, renderer2, renderer, dom, t, p, runScene, Bus;
// 工具
const { getMacro } = Utils;

// 拿资源
const setAssets = (assets) => {
  camera = assets.camera;
  scene = assets.scene;
  controls = assets.controls;
  renderer = assets.renderer;
  dom = assets.engineDom;
  t = assets.t;
};

//  页面接口总出口
function Change(runScene, onDone) {
  setAssets({ ...runScene.assetsEx.get(), t: this, runScene });

  t.runScene = runScene;

  t.onDone = onDone;

  // 场景事件
  this.events = null;
  // 工具
  this.tool = null;

  // 最后一帧加载回调
  t.runScene.on("complete", async () => {
    this.events = new Events();

    this.tool = new Tool();

    // 支架 模型选择
    this.choiceModel = new ChoiceModel();
    this.choiceModel.init();

    this.dispose = new Dispose();
    this.dispose.init();

    this.size = new Size();
    this.size.init();

    this.camera = new Camera();
    this.camera.init();

    // 灯光
    // this.light = new Light();
    // this.light.init();

    // 最小距离
    // controls.minDistance = 1000;

    // 最大距离
    // controls.maxDistance = 3000;

    // 基本配置
    (() => {
      t.runScene.script.playAll();

      // 入场动画
      t.runScene.cameraEx.setTemp("LFP_9kWhHV-pc初始", { time: 2 });

      // 场景的基本配置
      controls.maxPolarAngle = Math.PI / 2;

      console.log(t.runScene, 'runScene');

      // 关闭阴影
      if (t.runScene.modelEx.getModel("Shadow9k_8")) {
        t.runScene.modelEx.getModel("Shadow9k_8").visible = false;
      }

      // 加载回调
      t.onDone();
    })();
  });

  // 销毁
  this.dispose = () => runScene.dispose();
}

// 选择 模型
class ChoiceModel {
  // 电池模组 模型
  batteryModelMap = {};
  init() {
    // 获取模型
    this.getModel();
    // 切换模型
    bus.$on("switchMesh", this.choose.bind(this));
  }

  // 获取 模型
  getModel() {
    const tagsId = t.runScene.tags.get("LFP3000/HV");
    tagsId.map((childId) => {
      const model = t.runScene.modelEx.getById(childId);
      const childrenName = model.name;
      this.batteryModelMap[childrenName] = null;
      this.batteryModelMap[childrenName] = model;
    });
  }

  // 选择
  choose(chooseName) {
    // 显示隐藏 对应电池
    Object.keys(this.batteryModelMap).map((modelName) => {
      const model = this.batteryModelMap[modelName];
      if (modelName === chooseName) {
        model.visible = true;
      } else {
        if (!model.visible) return;
        model.visible = false;
      }
    });

    // 重置 时间线状态
    // t.dispose.resetGroup("组1");

    // 聚焦 动画
    this.focus(chooseName);
  }

  // 聚焦 动画
  focus(chooseName, time = 1) {
    console.log(chooseName, "chooseName");
    const animaName = `${chooseName}-pc初始`;
    t.runScene.cameraEx.setTemp(animaName, { time: 1 });
  }
}

// 拆解相关
class Dispose {
  // 时间线 映射表
  timeLineMap = {
    "LFP3000/HV": [
      "LFP_9kWhHV",
      "LFP_12kWhHV",
      "LFP_15kWhHV",
      "LFP_18kWhHV",
      "LFP_21kWhHV",
      "LFP_24kWhHV",
      "LFP_27kWhHV",
      "LFP_30kWhHV",
    ],

    LFP5000: ["LFP_5000"],

    "LFP5-10kWHLV": ["LFP_5kWhLV", "LFP_10kWhLV"],

    "Single-Phase": ["Single-Phase"],

    "Three-Phase": ["Three-Phase"],

    '光伏板': ['TOPCon-108cells', 'TOPCon-144cells'],
  };

  // 显示对应的标签
  openTips = {
    "TOPCon-108cells": { tipsName: 'TOPCon-108cells_文字', model: null },
    "TOPCon-144cells": { tipsName: "TOPCon-144cells_文字", model: null },
  };

  init() {
    // 拆解
    bus.$on("displayMesh", this.dispose.bind(this));
    // 恢复
    bus.$on("unDisplayMesh", this.unDisplayMesh.bind(this));
    // 重置所有 (0秒)
    bus.$on("resetAllNow", this.resetAllNow.bind(this));
    // 获取对应 标签 模型
    this.getCorrespondingTipsModel();
  }

  // 获取对应 标签 模型
  getCorrespondingTipsModel() {
    Object.keys(this.openTips).map((key) => {
      const { tipsName } = this.openTips[key];
      if (!tipsName || tipsName == undefined) return
      this.openTips[key].model = t.runScene.modelEx.getModel(tipsName);
    });
  }

  // 显示 关闭 对应的标签
  openCorresponding(machineName, isShow) {
    if (!this.openTips[machineName]) return;
    const { model } = this.openTips[machineName];
    model.visible = isShow;
  }

  // 拆解  bus
  dispose(meshName) {
    // 先炸开在拆解
    t.camera.angle(`${meshName}-pc炸开`, () => {
      // 指定 时间线
      Object.keys(this.timeLineMap).map((groupName) => {
        const meshArray = this.timeLineMap[groupName];
        if (meshArray.includes(meshName)) {
          t.runScene.timeLineEx.playGroup(groupName, {
            onOnceDone: () => {
              // 显示  对应的标签
              this.openCorresponding(meshName, true);
            }
          });
        }
      });

      // scene.globalConfig.setGlow(true);
    });
  }

  // 恢复 bus
  unDisplayMesh(meshName, isAll = false) {
    // 隐藏对应的标签
    this.openCorresponding(meshName, false);
    if (isAll) {
      this.playAllGroupOnce()
      this.resetAllNow();
    } else {
      // 指定 时间线
      Object.keys(this.timeLineMap).map((groupName) => {
        const meshArray = this.timeLineMap[groupName];
        if (meshArray.includes(meshName)) {
          t.runScene.timeLineEx.playGroup(groupName, {
            onlyReverse: true,
            onOnceDone: () => {
              // 先恢复时间线  初始视角
              t.camera.angle(`${meshName}-pc初始`);
            },
          });
        }
      });
    }
  }

  // 隐藏所有标签
  resetAllTips() {
    this.openCorresponding("TOPCon-108cells", false);
    this.openCorresponding('TOPCon-144cells', false);
  }

  // 执行 时间线
  playAllGroupOnce() {
    t.runScene.timeLineEx.playGroup("LFP3000/HV", { onlyReverse: true });
    t.runScene.timeLineEx.playGroup("LFP5000", { onlyReverse: true });
    t.runScene.timeLineEx.playGroup("LFP5-10kWHLV", { onlyReverse: true });
    t.runScene.timeLineEx.playGroup("Single-Phase", { onlyReverse: true });
    t.runScene.timeLineEx.playGroup("Three-Phase", { onlyReverse: true });
    t.runScene.timeLineEx.playGroup("光伏板", { onlyReverse: true });
    this.resetAllTips()
  }

  // 重置所有 时间线
  resetAllNow() {
    t.runScene.timeLineEx.resetGroup("LFP3000/HV");
    t.runScene.timeLineEx.resetGroup("LFP5000");
    t.runScene.timeLineEx.resetGroup("LFP5-10kWHLV");
    t.runScene.timeLineEx.resetGroup("Single-Phase");
    t.runScene.timeLineEx.resetGroup("Three-Phase");
    t.runScene.timeLineEx.resetGroup("光伏板");
    this.resetAllTips()
  }

  // 重置 时间线状态
  resetGroup(sceneName) {
    t.runScene.timeLineEx.resetGroup(sceneName);
  }
}

// 灯光
class Light {
  directionaLight = null;

  // 初始化
  init() {
    this.getModel();

    // 灯跟随移动
    t.runScene.cb.render.add("lightMove", () => {
      // this.directionaLight.position.copy(t.runScene.assetsEx.camera.position);
      // t.runScene.lightEx.update(this.directionaLight)
    });
  }

  // 获取 模型
  getModel() {
    this.directionaLight = t.runScene.modelEx.getModel("PointLight");
  }
}

// 尺寸
class Size {
  // 标签 名称
  labelModel = {};

  init() {
    this.getModel();
    // 展示 尺寸
    bus.$on("showSize", this.showSize.bind(this));
    // 隐藏 尺寸
    bus.$on("hiddenSize", this.hiddenSize.bind(this));
  }

  // 获取模型
  getModel() {
    const tagsId1 = t.runScene.tags.get("pc标签");
    this.labelModel["pc标签"] = {};
    tagsId1.map((childId) => {
      const model = t.runScene.modelEx.getById(childId);
      const childrenName = model.name;
      this.labelModel["pc标签"][childrenName] = null;
      this.labelModel["pc标签"][childrenName] = model;
      model.children.map((child) => {
        child.oldScale = {
          ...child.scale,
        };
      });
    });

    const tagsId2 = t.runScene.tags.get("iphone标签");
    this.labelModel["iphone标签"] = {};
    tagsId2.map((childId) => {
      const model = t.runScene.modelEx.getById(childId);
      const childrenName = model.name;
      this.labelModel["iphone标签"][childrenName] = null;
      this.labelModel["iphone标签"][childrenName] = model;
      model.children.map((child) => {
        child.oldScale = {
          ...child.scale,
        };
      });
    });
  }

  // 展示尺寸
  showSize(meshName) {
    const pc = this.labelModel["pc标签"];
    const model = pc[`${meshName}标签`];
    // model.visible = true;
    model.children.map((child) => {
      Utils.anima(
        {
          x: 0,
          y: 0,
          z: 0,
        },
        {
          ...child.oldScale,
        },
        0.5,
        (data) => {
          child.scale.x = data.x;
          child.scale.y = data.y;
          child.scale.z = data.z;
          if (data.x >= 0.1) {
            if (model.visible) return;
            model.visible = true;
          }
        }
      );
    });

    // 显示标尺视角
    t.camera.angle(`${meshName}-pc标尺`);
  }

  // 隐藏尺寸
  hiddenSize(meshName, isAll = false) {
    if (!meshName || isAll) {
      console.log("隐藏全部");
      this.hiddenAllSize();
    } else {
      console.log("隐藏部分");
      const pc = this.labelModel["pc标签"];
      const model = pc[`${meshName}标签`];
      if (!model.visible) return;
      model.children.map((child) => {
        Utils.anima(
          {
            x: child.scale.x,
            y: child.scale.y,
            z: child.scale.z,
          },
          {
            x: 0,
            y: 0,
            z: 0,
          },
          0.5,
          (data) => {
            child.scale.x = data.x;
            child.scale.y = data.y;
            child.scale.z = data.z;
          },
          () => {
            model.visible = false;
          }
        );
      });
    }
    // 回归视角
    t.camera.angle(`${meshName}-pc初始`);
  }

  // 隐藏 全部
  hiddenAllSize() {
    const pc = this.labelModel["pc标签"];
    Object.keys(pc).map((label) => {
      pc[label].children.map((child) => {
        if (!pc[label].visible) return;
        Utils.anima(
          {
            x: child.scale.x,
            y: child.scale.y,
            z: child.scale.z,
          },
          {
            x: 0,
            y: 0,
            z: 0,
          },
          0.5,
          (data) => {
            child.scale.x = data.x;
            child.scale.y = data.y;
            child.scale.z = data.z;
          },
          () => {
            pc[label].visible = false;
          }
        );
      });
    });
  }
}

// 摄像头
class Camera {
  name = "camera";
  init() {
    // 相机视角
    bus.$on("camera-angle", this.angle.bind(this));
  }
  // 相机视角
  angle(name, fn) {
    t.runScene.cameraEx.setTemp(`${name}`, {
      time: 1,
      onComplete: () => {
        fn && fn();
        t.runScene.cameraEx.animaData.onBreak = null
      },
      onBreak: () => {
        fn && fn();
      },
    });
  }
}

// 工具方法 ( 可封的公共方法 )
class Tool {
  // 相机 动画
  cameraAnima(position, time = 1, fn) {
    t.events.closeAnimaAtStart.cameraAnima = Utils.anima(
      {
        cx: t.runScene.assetsEx.camera.position.x,
        cy: t.runScene.assetsEx.camera.position.y,
        cz: t.runScene.assetsEx.camera.position.z,
        tx: t.runScene.assetsEx.controls.target.x,
        ty: t.runScene.assetsEx.controls.target.y,
        tz: t.runScene.assetsEx.controls.target.z,
      },
      {
        ...position,
      },
      time,
      (data) => {
        t.runScene.assetsEx.camera.position.set(data.cx, data.cy, data.cz);
        t.runScene.assetsEx.controls.target.set(data.tx, data.ty, data.tz);
        t.runScene.assetsEx.camera.updateProjectionMatrix();
        t.runScene.assetsEx.controls.update();
      },
      () => {
        fn && fn();
      }
    );
  }
  // 模型 透明度 渐变动画
  showAnima(info) {
    const { model, isShow, time, cb, opacity } = info;
    const models = [];
    model.traverse((m) => {
      if (m.type === "Group") return;
      if (m.type === "Object3D") return;
      m.material.transparent = true;
      isShow ? (m.material.opacity = 0) : null;
      models.push(m);
    });
    if (isShow) model.visible = isShow;
    Utils.anima(
      { opc: isShow ? 0 : opacity || 1 },
      { opc: isShow ? opacity || 1 : 0 },
      time,
      (data) => {
        models.map((m) => (m.material.opacity = data.opc));
      },
      () => {
        if (!isShow) model.visible = isShow;
        cb && cb();
      }
    );
  }
}

// 基本事件
class Events {
  downPosition = { x: 0, y: 0 };

  closeAnimaAtStart = { enterAnima: "" };

  constructor() {
    t.runScene.assetsEx.controls.addEventListener("start", this.controlStart);
    t.runScene.cb.model.setSelect.add(
      "trigger-click",
      this.triggerClick.bind(this)
    );
    t.runScene.cb.events.pointer.down.add("trigger", (e) =>
      t.runScene.modelEx.selectNull()
    );
    document.addEventListener("click", (e) => { });

    // this.autoRotate();
  }

  autoRotate() {
    t.runScene.cb.render.add("autoRotate", () => {
      t.runScene.assetsEx.controls.autoRotate = true;
      t.runScene.assetsEx.controls.autoRotateSpeed = 2;
      t.runScene.assetsEx.controls.update();
    });
  }

  triggerClick = (model) => {
    if (!model) return;
    console.log("点击的模型:", model);
    console.log(
      `cx:${camera.position.x}, cy:${camera.position.y}, cz:${camera.position.z}, tx:${controls.target.x}, ty:${controls.target.y}, tz:${controls.target.z} `,
      "位置"
    );
    console.log(t.runScene.modelEx.getModel("DirectionalLight_2"), "灯");
  };

  // 清除动画
  controlStart = () => {
    this.closeAnmia();
  };

  // 暂停动画 并清空内容 item就是那个动画
  closeAnmia() {
    Object.values(this.closeAnimaAtStart).map((i) => i && i.kill());
  }

  dispose() {
    controls.removeEventListener("start", this.controlStart);
  }
}

export default Change;
