/**
 * @description vue状态管理
 */

import store from "vuex"
import Vue from "vue"

Vue.use(store)

export default new store.Store({
    state: {
        mesh:"LFP_9kWhHV",
        meshType:"LFP9-30kWh/HV", // "LFP 5-10KWH/LV", //LFP 5000
       
        //路由切换状态
        rc:false,
        // 登录状态
        loginState: false,
        // 用户信息
        userInfo: {

        },
        threeLoad: false,//，模型加载
        isDisabled: true,//是否可点击
    },
    mutations: {
    },
    actions: {
    }
})
